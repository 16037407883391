import { useState, useEffect } from 'react';

import './mycourses.css';
import CardList from '../card-list/card-list';
import SearchBox from '../search-box/search-box';


const App = () =>{

  const [searchField, setSearchField] = useState('');
  const [monsters, setMonsters] = useState([]);
  const [filteredMonsters, setFilteredMonsters] = useState(monsters);

  useEffect(() => {
      /* fetch('https://jsonplaceholder.typicode.com/users') */
    fetch('http://alphaonemedia.ca/teaching/courselist.json')
      .then((response) => response.json())
      .then((users) => setMonsters(users));
      }, []);

  useEffect (() => {
    const newFilteredMonsters = monsters.filter((monster)=>{
      return monster.name.toLocaleLowerCase().includes(searchField);
    });
    setFilteredMonsters(newFilteredMonsters);
  }, [monsters, searchField])

  const onSearchChange = (event) => {
    const searchFieldString = event.target.value.toLocaleLowerCase();
    setSearchField(searchFieldString);
    
  }
  

  return (
    <div className='App'>
      {/* <h1 className='app-title'>Monster Personal</h1>
      <SearchBox 
        onChangeHandler={onSearchChange} 
        placeholder ='Search Monsters' 
        className='Monsters-Search-Box'/> */}
      <CardList monsters={filteredMonsters}  />
    </div>
  );

}

export default App;
