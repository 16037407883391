import React from "react";
import "./Home.css";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import "../components/hero/mailchimpForm.css";
import "./courses.css";
import "../components/card/card.css";

import MyCourses from "../components/courses/mycourses";

import MailchimpForm from "../components/hero/mailchimp";

const Courses = () => {
  return (
    <section>
      <section className="coursestop"></section>
      <section className="courseportfolio">
        <h1>My Courses Portfolio</h1>
      </section>

      <section className="mycourselist">
        <div className="courserow">
          <div className="col-1-of-3">
            <div className="coursecard">
              <div className="frontcard">
                <div className="image">
                  <img src="http://www.alphaonemedia.ca/teaching/PowerBIData.png" />
                </div>
                <div className="coursetitle">Power BI Data Anaylsis</div>
                <div className="coursedetails">
                  <ul className="course-ul">
                    <li className="course-li">
                      <b>Instructor Led:</b> 2 Days
                    </li>
                    <li className="course-li">
                      <b>Video Course:</b> 5 hrs 40 min
                    </li>
                  </ul>
                </div>
              </div>
              <div className="backcard">
                <div className="backcontent">
                  <div className="backtext">
                    <p className="desc">
                      <b>SUMMARY: </b>The era of big data has exploded and is
                      already here and learning how to leverage that data
                      effectively and provide timely insights can be a
                      competitive advantage. Creating eye catching
                      visualizations is the way to catch the attention of the
                      management teams that have to make the executive decisions
                      to move your organization forward.
                    </p>
                    <p className="prereq">
                      <b>PREREQ'S: </b>Prior to enrolling in this course, you
                      must have advanced Excel skill set. Having knowledge of
                      the Power Query add-on for Excel or a working knowledge of
                      databases like MS Access or SQL would be an asset but is
                      not required. I will teach you everything you need to
                      know. No knowledge of Power BI is required to take this
                      course, but the willingness to learn is!
                    </p>
                  </div>
                  <a
                    href="https://www.udemy.com/course/power-bi-data-analysis/"
                    className="button"
                    target="_blank"
                  >
                    More Details
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="coursecolspacer"></div>

          <div className="col-1-of-3">
            <div className="coursecard">
              <div className="frontcard">
                <div className="image">
                  <img src="http://www.alphaonemedia.ca/teaching/PowerBIDax.png" />
                </div>
                <div className="coursetitle">Power BI DAX Super User</div>
                <div className="coursedetails">
                  <ul className="course-ul">
                    <li className="course-li">
                      <b>Instructor Led:</b> 2 Days
                    </li>
                    <li className="course-li">
                      <b>Video Course:</b> 5 hrs 23 min
                    </li>
                  </ul>
                </div>
              </div>
              <div className="backcard">
                <div className="backcontent">
                  <div className="backtext">
                    <p className="desc">
                      <b>SUMMARY: </b>Welcome to my intermediate level for Power
                      BI users who want to delve deeper into the DAX functional
                      language. After taking my Power BI Data Analysis Course
                      (for beginners) you now will be ready to take your skills
                      to the next level and really get a grasp on the DAX
                      language that is used in Power BI formulas.
                    </p>
                    <p className="prereq">
                      <b>PREREQ'S: </b>Prior to enrolling this course, you
                      should have taken my Power BI Data Analysis course offered
                      above, or another equivalent introductory course to Power
                      BI.
                    </p>
                  </div>
                  <a
                    href="https://www.udemy.com/course/microsoft-power-bi-dax-super-user-intermediate/"
                    className="button"
                    target="_blank"
                  >
                    More Details
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="coursecolspacer"></div>
          <div className="col-1-of-3">
            <div className="coursecard">
              <div className="frontcard">
                <div className="image">
                  <img src="http://www.alphaonemedia.ca/teaching/RPADesktop.jpg" />
                </div>
                <div className="coursetitle">RPA Automate Desktop</div>
                <div className="coursedetails">
                  <ul className="course-ul">
                    <li className="course-li">
                      <b>Instructor Led:</b> 2 Days
                    </li>
                    <li className="course-li">
                      <b>Video Course:</b> 6 hrs 12 min
                    </li>
                  </ul>
                </div>
              </div>
              <div className="backcard">
                <div className="backcontent">
                  <div className="backtext">
                    <p className="desc">
                      <b>SUMMARY: </b>Throughout the course, students will gain
                      proficiency in building, configuring, and deploying
                      software robots capable of executing a wide range of
                      tasks. The course will also cover data manipulation,
                      enabling students to extract, process, and transfer
                      information across various applications and systems
                      seamlessly.
                    </p>
                    <p className="prereq">
                      <b>PREREQ'S: </b>No computer coding is required, but
                      taking my Power Automate Foundations course would be an
                      asset as well as you must have Power Automate Desktop
                      installed on your local computer.
                    </p>
                  </div>
                  <a
                    href="https://www.udemy.com/course/rpa-w-power-automate-desktop/"
                    className="button"
                    target="_blank"
                  >
                    More Details
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="courserowspacer"></div>
        <div className="courserow">
          <div className="col-1-of-3">
            <div className="coursecard">
              <div className="frontcard">
                <div className="image">
                  <img src="http://www.alphaonemedia.ca/teaching/PowerAutoCrash.png" />
                </div>
                <div className="coursetitle">Power Automate Crash Course</div>
                <div className="coursedetails">
                  <ul className="course-ul">
                    <li className="course-li">
                      <b>Instructor Led:</b> N/A
                    </li>
                    <li className="course-li">
                      <b>Video Course:</b> 1 hr 37 min
                    </li>
                  </ul>
                </div>
              </div>
              <div className="backcard">
                <div className="backcontent">
                  <div className="backtext">
                    <p className="desc">
                      <b>SUMMARY: </b>In this fast paced Power Automate Crash
                      Course - you will watch me automate the ever so popular
                      Corporate Expense Claim process that every business has
                      today. From start to finish, I will build a form that
                      collects the expense claim data with an attachment, submit
                      the data to Excel and a custom SharePoint List.
                    </p>
                    <p className="prereq">
                      <b>PREREQ'S: </b>Prior Power Automate Knowledge with O365
                      E5 License that allows you to use Power Automate. Having a
                      SharePoint site with Owner permissions to create lists and
                      Document library is required. Access to MS Forms and to
                      your OneDrive.
                    </p>
                  </div>
                  <a
                    href="https://www.udemy.com/course/power-automate-crash-course/"
                    className="button"
                    target="_blank"
                  >
                    More Details
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="coursecolspacer"></div>

          <div className="col-1-of-3">
            <div className="coursecard">
              <div className="frontcard">
                <div className="image">
                  <img src="http://www.alphaonemedia.ca/teaching/PowerAutoFound.png" />
                </div>
                <div className="coursetitle">Power Automate Foundations</div>
                <div className="coursedetails">
                  <ul className="course-ul">
                    <li className="course-li">
                      <b>Instructor Led:</b> 2 Days
                    </li>
                    <li className="course-li">
                      <b>Video Course:</b> 4 hrs 57 min
                    </li>
                  </ul>
                </div>
              </div>
              <div className="backcard">
                <div className="backcontent">
                  <div className="backtext">
                    <p className="desc">
                      <b>SUMMARY: </b>Welcome to my foundations course for those
                      who want to start using Microsoft's Power Automate. If you
                      have business process such as expense claim form
                      approvals, retrieving data from SharePoint Lists or simply
                      notifying you when documents are added to a SharePoint
                      Library, then Power Automate is the tool of choice.
                    </p>
                    <p className="prereq">
                      <b>PREREQ'S: </b>Prior to enrolling in this course, you
                      must have advanced Excel skill set. No computer coding is
                      required, but logical thinking is an asset. Must have an
                      O365 license and have Power Automate attached to your
                      account (It's Free). Must have access to your own
                      SharePoint Online site and OneDrive.
                    </p>
                  </div>
                  <a
                    href="https://www.udemy.com/course/power-automate-foundations/"
                    className="button"
                    target="_blank"
                  >
                    More Details
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="coursecolspacer"></div>

          <div className="col-1-of-3">
            <div className="coursecard">
              <div className="frontcard">
                <div className="image">
                  <img src="http://www.alphaonemedia.ca/teaching/PowerAutoAdv.png" />
                </div>
                <div className="coursetitle">Power Automate Advanced</div>
                <div className="coursedetails">
                  <ul className="course-ul">
                    <li className="course-li">
                      <b>Instructor Led:</b> 2 Days
                    </li>
                    <li className="course-li">
                      <b>Video Course:</b> 6 hrs 30 min
                    </li>
                  </ul>
                </div>
              </div>
              <div className="backcard">
                <div className="backcontent">
                  <div className="backtext">
                    <p className="desc">
                      <b>SUMMARY: </b>Enrolling in an advanced Power Automate
                      course is a transformative step for individuals seeking to
                      become experts in the realm of workflow automation and
                      process optimization. In this dynamic program, students
                      will delve deep into the capabilities of Microsoft Power
                      Automate, gaining the knowledge and proficiency needed to
                      streamline business operations and enhance productivity.
                    </p>
                    <p className="prereq">
                      <b>PREREQ'S: </b>Taking my Power Automate Foundations
                      course would be required along with having an O365 E5
                      License with access to Power Automate, MS Forms and
                      OneDrive.
                    </p>
                  </div>
                  <a
                    href="https://www.udemy.com/course/power-automate-advanced/"
                    className="button"
                    target="_blank"
                  >
                    More Details
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="courserowspacer"></div>
        <div className="courserow">
          <div className="col-1-of-3">
            <div className="coursecard">
              <div className="frontcard">
                <div className="image">
                  <img src="http://www.alphaonemedia.ca/teaching/PowerAppsCrash.jpg" />
                </div>
                <div className="coursetitle">Power Apps Crash Course</div>
                <div className="coursedetails">
                  <ul className="course-ul">
                    <li className="course-li">
                      <b>Instructor Led:</b> N/A
                    </li>
                    <li className="course-li">
                      <b>Video Course:</b> 1 hr 36 min
                    </li>
                  </ul>
                </div>
              </div>
              <div className="backcard">
                <div className="backcontent">
                  <div className="backtext">
                    <p className="desc">
                      <b>SUMMARY: </b>In this fast paced Power Apps Crash Course
                      - you will watch me create a complete University Campus
                      app from start to finish that will allow users to navigate
                      campus life. Students and Faculty will be able to view the
                      student database, the faculty members and the courses
                      offered at the unversity. If the students wish to apply to
                      take a course it will then trigger a Power Autmate
                      workflow with an approval process.
                    </p>
                    <p className="prereq">
                      <b>PREREQ'S: </b>No computer coding is required, but
                      logical thinking is an asset.Must have an O365 license and
                      have Power Apps attached to your account. Having a
                      SharePoint site with Owner permissions to create lists is
                      required.
                    </p>
                  </div>
                  <a
                    href="https://www.udemy.com/course/power-apps-crash-course/"
                    className="button"
                    target="_blank"
                  >
                    More Details
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="coursecolspacer"></div>

          <div className="col-1-of-3">
            <div className="coursecard">
              <div className="frontcard">
                <div className="image">
                  <img src="http://www.alphaonemedia.ca/teaching/PowerAppsFound.png" />
                </div>
                <div className="coursetitle">Power Apps Foundations</div>
                <div className="coursedetails">
                  <ul className="course-ul">
                    <li className="course-li">
                      <b>Instructor Led:</b> 2 Days
                    </li>
                    <li className="course-li">
                      <b>Video Course:</b> 5 hrs 24 min
                    </li>
                  </ul>
                </div>
              </div>
              <div className="backcard">
                <div className="backcontent">
                  <div className="backtext">
                    <p className="desc">
                      <b>SUMMARY: </b>Welcome to my foundations course for those
                      who want to start using Microsoft's PowerApps. If you need
                      to create new forms to collect information from your users
                      or you are a current, InfoPath user and need to start
                      replacing those forms before that products end of life
                      then this course is for you.
                    </p>
                    <p className="prereq">
                      <b>PREREQ'S: </b>No computer coding is required, but
                      logical thinking is an asset.Must have an O365 license and
                      have PowerApps attached to your account. Must have access
                      to your own SharePoint Online site and OneDrive.(It's
                      Free).
                    </p>
                  </div>
                  <a
                    href="https://www.udemy.com/course/powerapps-foundations/"
                    className="button"
                    target="_blank"
                  >
                    More Details
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="coursecolspacer"></div>

          <div className="col-1-of-3">
            <div className="coursecard">
              <div className="frontcard">
                <div className="image">
                  <img src="http://www.alphaonemedia.ca/teaching/PowerAppsAdv.png" />
                </div>
                <div className="coursetitle">Power Apps Advanced</div>
                <div className="coursedetails">
                  <ul className="course-ul">
                    <li className="course-li">
                      <b>Instructor Led:</b> 2 Days
                    </li>
                    <li className="course-li">
                      <b>Video Course:</b> 4 hrs 30 min
                    </li>
                  </ul>
                </div>
              </div>
              <div className="backcard">
                <div className="backcontent">
                  <div className="backtext">
                    <p className="desc">
                      <b>SUMMARY: </b>Taking the Power Apps Advanced course is
                      an exciting journey for individuals looking to elevate
                      their skills in the world of low-code app development. In
                      this comprehensive program, students will delve deeper
                      into the intricacies of Microsoft Power Apps, unlocking a
                      wealth of knowledge and capabilities to create
                      sophisticated, custom applications tailored to specific
                      business needs.
                    </p>
                    <p className="prereq">
                      <b>PREREQ'S: </b>Prior to enrolling in this course, you
                      must have taken my Power Apps Foundations course and have
                      an O365 E5 License that allows you to use Power Automate.
                      Having a SharePoint site with Owner permissions to create
                      lists is also required.
                    </p>
                  </div>
                  <a
                    href="https://www.udemy.com/course/power-apps-advanced/"
                    className="button"
                    target="_blank"
                  >
                    More Details
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="courserowspacer"></div>
        <div className="courserow">
          <div className="col-1-of-3">
            <div className="coursecard">
              <div className="frontcard">
                <div className="image">
                  <img src="http://www.alphaonemedia.ca/teaching/SPSiteUser.png" />
                </div>
                <div className="coursetitle">SharePoint Online Site User</div>
                <div className="coursedetails">
                  <ul className="course-ul">
                    <li className="course-li">
                      <b>Instructor Led:</b> 1 Day
                    </li>
                    <li className="course-li">
                      <b>Video Course:</b> 1 hrs 42 min
                    </li>
                  </ul>
                </div>
              </div>
              <div className="backcard">
                <div className="backcontent">
                  <div className="backtext">
                    <p className="desc">
                      <b>SUMMARY: </b>Welcome to my SharePoint User course for
                      those users who want to understand the fundamentals of how
                      to use the basic features that SharePoint provides. From
                      handling documents, to creating announcments and calendar
                      events, this course will teach the user how to use all the
                      features available to them when they have the basic
                      permission of contribute. This is a Free crash course for
                      anyone who wants to get using thier SharePoint Online
                      today!
                    </p>
                    <p className="prereq">
                      <b>PREREQ'S: </b>Prior to enrolling in this course, you
                      must have access to a developed SharePoint site or a
                      SharePoint sandbox to do the activites laid out in this
                      manual. If the webparts have not been created for you, you
                      will also have to have them created before you can use
                      them.
                    </p>
                  </div>
                  <a
                    href="https://www.udemy.com/course/sharepoint-online-site-user-crash-course/"
                    className="button"
                    target="_blank"
                  >
                    More Details
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="coursecolspacer"></div>
          <div className="col-1-of-3">
            <div className="coursecard">
              <div className="frontcard">
                <div className="image">
                  <img src="http://www.alphaonemedia.ca/teaching/6.png" />
                </div>
                <div className="coursetitle">SharePoint Online Site Owner</div>
                <div className="coursedetails">
                  <ul className="course-ul">
                    <li className="course-li">
                      <b>Instructor Led:</b> 1 Day
                    </li>
                    <li className="course-li">
                      <b>Video Course:</b> 5 hrs 40 min
                    </li>
                  </ul>
                </div>
              </div>
              <div className="backcard">
                <div className="backcontent">
                  <div className="backtext">
                    <p className="desc">
                      <b>SUMMARY: </b>Welcome to my SharePoint Site Owner course
                      for those who are designated a Site Owner or Administrator
                      for their SharePoint Online / O365 site. You will learn
                      the necessary skills to develop and maintain your
                      corporate site and learn to apply proper permissions to
                      the right people have the right access.
                    </p>
                    <p className="prereq">
                      <b>PREREQ'S: </b>Must have O365 Subscription with access
                      to a SharePoint Site. Must be an Owner / Administrator to
                      that site - be able to create web parts & apps.
                    </p>
                  </div>
                  <a
                    href="https://www.udemy.com/course/sharepoint-online-site-owner/"
                    className="button"
                    target="_blank"
                  >
                    More Details
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="coursecolspacer"></div>
          <div className="col-1-of-3">
            <div className="coursecard">
              <div className="frontcard">
                <div className="image">
                  <img src="http://www.alphaonemedia.ca/teaching/7.png" />
                </div>
                <div className="coursetitle">SharePoint Online Site Admin</div>
                <div className="coursedetails">
                  <ul className="course-ul">
                    <li className="course-li">
                      <b>Instructor Led:</b> 1 Day
                    </li>
                    <li className="course-li">
                      <b>Video Course:</b> 4 hrs 25 min
                    </li>
                  </ul>
                </div>
              </div>
              <div className="backcard">
                <div className="backcontent">
                  <div className="backtext">
                    <p className="desc">
                      <b>SUMMARY: </b>Welcome to your SharePoint Site
                      Administrator course for those who have been designated an
                      Administrator for a corporate SharePoint Online / O365
                      site. In this course you will learn the necessary skills
                      to maintain and administer your site thru the site
                      settings panel and the the holy grail - SharePoint Admin
                      Central panel.
                    </p>
                    <p className="prereq">
                      <b>PREREQ'S: </b>Must have O365 Subscription with access
                      to a SharePoint Site. Must be an Administrator to that
                      site and access to SharePoint Central Admin. Must have
                      taken my previous SharePoint Site Owner class or have
                      equivalent experience in being an owner of SharePoint
                      Sites.
                    </p>
                  </div>
                  <a
                    href="https://www.udemy.com/course/sharepoint-online-site-administrator/"
                    className="button"
                    target="_blank"
                  >
                    More Details
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="courserowspacer"></div>
        <div className="courserow">
          <div className="col-1-of-3">
            <div className="coursecard">
              <div className="frontcard">
                <div className="image">
                  <img src="http://www.alphaonemedia.ca/teaching/Access1.png" />
                </div>
                <div className="coursetitle">Access 365 Foundations</div>
                <div className="coursedetails">
                  <ul className="course-ul">
                    <li className="course-li">
                      <b>Instructor Led:</b> 2 Days
                    </li>
                    <li className="course-li">
                      <b>Video Course:</b> 5 hrs 43 min
                    </li>
                  </ul>
                </div>
              </div>
              <div className="backcard">
                <div className="backcontent">
                  <div className="backtext">
                    <p className="desc">
                      <b>SUMMARY: </b>If you are a small organization or self
                      employed and need to move beyond using Excel to hold your
                      data, then you need MS Access 365. Access 365 is a desktop
                      relational database that has been around for 30 years and
                      does the job needed to hold your data. But in fact, it
                      does more than just hold your data - you can execute
                      complex queries to run your reports and get the data
                      analysis you need on your organization.
                    </p>
                    <p className="prereq">
                      <b>PREREQ'S: </b>No Database knowledge is required for
                      this course. Having strong Excel skills in functions will
                      greatly enhance your learning experience. You must have MS
                      Access installed on your system - subscription to O365
                      will be necessary.
                    </p>
                  </div>
                  <a
                    href="https://www.udemy.com/course/access-365-foundations/"
                    className="button"
                    target="_blank"
                  >
                    More Details
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="coursecolspacer"></div>

          <div className="col-1-of-3">
            <div className="coursecard">
              <div className="frontcard">
                <div className="image">
                  <img src="http://www.alphaonemedia.ca/teaching/Access2.png" />
                </div>
                <div className="coursetitle">Access 365 Intermediate</div>
                <div className="coursedetails">
                  <ul className="course-ul">
                    <li className="course-li">
                      <b>Instructor Led:</b> 2 Days
                    </li>
                    <li className="course-li">
                      <b>Video Course:</b> 5 hrs 3 min
                    </li>
                  </ul>
                </div>
              </div>
              <div className="backcard">
                <div className="backcontent">
                  <div className="backtext">
                    <p className="desc">
                      <b>SUMMARY: </b>After taking my Access 365 Foundations
                      course you are now ready for the next level: Access 365
                      Intermediate. Continue your educational journey with
                      up-to-date content on how to take your Access 365 Database
                      to the next level by creating Action Queries, using Sub
                      Forms, and creating Dialog Boxes for your users.
                    </p>
                    <p className="prereq">
                      <b>PREREQ'S: </b>Must have taken my Access O365
                      Foundations course or equivelant database experience.
                      Having strong Excel skills in functions will greatly
                      enhance your learning experience. You must have MS Access
                      installed on your system - subscription to 365 will be
                      necessary.
                    </p>
                  </div>
                  <a
                    href="https://www.udemy.com/course/access365intermediate/"
                    className="button"
                    target="_blank"
                  >
                    More Details
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="coursecolspacer"></div>

          <div className="col-1-of-3">
            <div className="coursecard">
              <div className="frontcard">
                <div className="image">
                  <img src="http://www.alphaonemedia.ca/teaching/Access3.png" />
                </div>
                <div className="coursetitle">Access 365 Expert</div>
                <div className="coursedetails">
                  <ul className="course-ul">
                    <li className="course-li">
                      <b>Instructor Led:</b> 2 Days
                    </li>
                    <li className="course-li">
                      <b>Video Course:</b> 3 hrs 32 min
                    </li>
                  </ul>
                </div>
              </div>
              <div className="backcard">
                <div className="backcontent">
                  <div className="backtext">
                    <p className="desc">
                      <b>SUMMARY: </b>The ultimate Access 365 Expert course is
                      now available for all those students who have taken my
                      Access 365 Foundations & Intermediate courses or for those
                      students with Access experience and just want to learn any
                      cool nuggets of information that other courses have left
                      out. This course will put the final touches on your
                      learning journey with Access Databases to learn how to
                      auto populate combo boxes, creating data access macros as
                      well as regular macros, embedding macros, hack proofing
                      read only objects and deploying multi-user applications.{" "}
                    </p>
                    <p className="prereq">
                      <b>PREREQ'S: </b>Must have taken my Access O365
                      Intermediate course or equivelant database experience.
                      Logical and problem solving skills.You must have MS Access
                      installed on your system - subscription to O365 will be
                      necessary.
                    </p>
                  </div>
                  <a
                    href="https://www.udemy.com/course/access-365-expert/"
                    className="button"
                    target="_blank"
                  >
                    More Details
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="courserowspacer"></div>
        <div className="courserow">
          <div className="col-1-of-3">
            <div className="coursecard">
              <div className="frontcard">
                <div className="image">
                  <img src="http://www.alphaonemedia.ca/teaching/PowerPagesFound.png" />
                </div>
                <div className="coursetitle">Power Pages Foundations</div>
                <div className="coursedetails">
                  <ul className="course-ul">
                    <li className="course-li">
                      <b>Instructor Led:</b> 2 Days
                    </li>
                    <li className="course-li">
                      <b>Video Course:</b> 3 hrs 37 min
                    </li>
                  </ul>
                </div>
              </div>
              <div className="backcard">
                <div className="backcontent">
                  <div className="backtext">
                    <p className="desc">
                      <b>SUMMARY: </b>If you are a small to mid-size
                      organization and you are using a Microsoft O365
                      subscription for all your office products and now require
                      a front facing public access website - then Power Pages is
                      now available for you to use. Power Pages is a content
                      management system (CMS) that will take your site to the
                      next level and allow integration with Dataverse and other
                      Power Platform products.
                    </p>
                    <p className="prereq">
                      <b>PREREQ'S: </b>No coding experience is required.
                      Knowledge of other Content Management systems (CMS) would
                      be an asset (WordPress). Global Administrator role to your
                      organizations domain would be an asset.
                    </p>
                  </div>
                  <a
                    href="https://www.udemy.com/course/microsoftpowerpages/"
                    className="button"
                    target="_blank"
                  >
                    More Details
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="hero6">
        <div className="hero-content2">
          <table>
            <tr>
              <td className="btm-column1">
                <b>ALPHA ONE MEDIA</b>
              </td>
              <td className="btm-column2">
                <b>LINKEDIN</b>
              </td>
              <td className="btm-column2">
                <b>QUICK MENU</b>
              </td>
              <td className="btm-column2">
                <b>JOIN MY COMMUNITY</b>
              </td>
            </tr>
            <tr>
              <td className="btm-column1">
                Alpha One Media is an IT consulting company that specializes in
                Microsoft training both in classroom and Virtual. As well as
                development of video courses, SharePoint sites and PowerApps.
              </td>
              <td className="btm-column2">
                <a
                  href="https://www.linkedin.com/in/robinsolonenko/"
                  target="_blank"
                >
                  My LinkedIn Profile
                </a>
              </td>
              <td className="btm-column3">
                Home
                <br />
                About Me
                <br />
                Course
                <br />
                Services
                <br />
                Contact Me
              </td>
              <td>
                <MailchimpForm />
              </td>
            </tr>
          </table>
        </div>
      </section>

      <Footer />
    </section>
  );
};

export default Courses;
