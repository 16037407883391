import { Component } from "react";
import './search-box.css';

class SearchBox extends Component {

    render() {
        return (
            <input 
            /* className='search-box'  */
            className={'search-box ${this.props.className}'}
            type='search' 
            /* placeholder='enter a word' */
            placeholder ={this.props.placeholder}
            onChange={this.props.onChangeHandler}
            />
        )
    }
}

export default SearchBox;