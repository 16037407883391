
import React from "react";
import './Home.css';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import '../components/hero/mailchimpForm.css';

import  MailchimpForm from '../components/hero/mailchimp';

const Courses = () => {
  return (
    <section>
   
    <section className="coursestop">
      </section>
    <section className="instructorled">
     <h1>MICROSOFT TRAINING</h1>
     <div className="hero-content">
      <table classname='table'>
         <tr>
         <td className='column1courses'><h2 style={{display: 'flex', fontWeight:'bold',color: 'brown', justifyContent: 'center', alignItems: 'center'}}>INSTRUCTOR LED</h2>I have been instructing courses since 1997 in all kinds of settings. From colleges
          to boardrooms, from course specific to corporate roll outs and from downtown offices to remote locations.<br/><br/> There is 
          no arguement that adults learn best from hands on training when they can have in instructor help them along the way.
           My students perform the actions as I am instructing with real world problems then immediately apply those skills
           with exercises they can understand.  They can even bring in their own data and files and I can help them apply what
           they have learned.<br/><br/> If you have a dedicated computer lab within your organization or laptops that can be
            set up in your board room, I can instruct any of the Microsoft courses you require by bringing me in house and
             saving you money.</td>
             <td className='column3'></td>
          <td classname='column2courses'><h2 style={{display: 'flex', fontWeight:'bold', color: 'brown', justifyContent: 'center', alignItems: 'center'}}>VIRTUAL TRAINING</h2>Although Virtual Instruction has been around for awhile it has not been widely
           utilized until recently. The recent pandemic has pushed companies to work remotely and they are finding that
           employees can work just as effective and efficiently from home.<br/><br/> Virtual training can reach more
           employees across geographical locations at a fraction of the price.  You will not have to pay travel expenses
           and they can still get personalized training.<br/><br/>Using such platforms as Zoom or MS Teams, the set up is
            easy and all your employees need is an internet connection along with speakers/mic installed
             (which come with most computers). I have never had a problem with connection speeds and with my high
              end video/audio system, students get a clear crisp video of me and my audio. Let me help you go Virtual today!</td>
          </tr>
          <tr>
          <td classname='column1courses'><img src='http://www.alphaonemedia.ca/teaching/ClassRoom2.png'></img></td>
          <td></td>
          <td classname='column1courses'><img src='http://www.alphaonemedia.ca/teaching/OnlineTraining.png'></img></td>
          </tr>
       </table>
       </div>
   </section>
   <section className="hero6">
      <div className="hero-content2">
       <table>
        <tr>
          <td className='btm-column1'><b>ALPHA ONE MEDIA</b></td>
          <td className='btm-column2'><b>LINKEDIN</b></td>
          <td className='btm-column2'><b>QUICK MENU</b></td>
          <td className='btm-column2'><b>JOIN MY COMMUNITY</b></td>
        </tr>
        <tr>
          <td className='btm-column1'>Alpha One Media is an IT consulting company that specializes in Microsoft training both in classroom and Virtual. 
             As well as development of video courses, SharePoint sites and PowerApps.</td>
          <td className='btm-column2'><a href='https://www.linkedin.com/in/robinsolonenko/' target='_blank' >My LinkedIn Profile</a></td>
          <td className='btm-column3'>Home<br/>
          About Me<br/>
          Course<br/>
          Services<br/>
          Contact Me</td>
          <td>
          <MailchimpForm />
          </td>
        </tr>
       </table>
      </div>
      </section>
      <Footer />
   </section>
  );
};

export default Courses;
