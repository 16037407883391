import React from "react";
import "./Home.css";
import "./database.css"
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import "../components/hero/mailchimpForm.css";

import MailchimpForm from "../components/hero/mailchimp";

const Courses = () => {
  return (
    <section>
      <section className="coursestop"></section>
      
      <section className="databasedev">
        <div className="dev-center dev-margin-top">
          <h1 className="main-heading">
            DATABASE DEVELOPMENT
            
          </h1>
        </div>
        <div className="row">
          <div className="col-1-of-2">
            
            <p className="paragraph">
                A lot of companies are not large enough to purchase software
                such as SAP/Oracle or pay thousands of dollars in licensing fees per
                year to purchase some off the shelve database for your industry
                At the same time, all the data your collecting, usually in
                Excel, can no longer hold the capacity of all your data. Your solution is
                to create a database in-house using SQL Cloud with MS Access front end or
                Microsoft's Dataverse and use Power Apps as your front end and I can help you with that.
            </p>
            <p className="paragraph">
                I have designed databases for over 20 years and
                have written manuals for a local Post Secondary school for these
                products. I can design, implement and deploy your database,
                taking your data from Excel into a much better way of reporting,
                with relevant features such as emailing reports and creating
                dashboards for your employees. Furthermore, integrate your
                data into Power BI for powerful visual reports for your
                management team both for hard copies and online viewing on
                mobile devices.
            </p>
            <p className="paragraph">
                Furthermore, I can then train your employees to maintain and
                support your database to add new features as your organization
                grows or I can help maintain your systems as well. Having
                accurate data at your fingertips is kingpin to any organization
                and it all starts with creating a central data location while
                using online apps to have your data accessible to anyone in the
                organization from anywhere, and I can you get there!
            </p>
          </div>
          <div className="col-2-of-2">
          <div class="composition">
                           {/*  <img src="images/SQL_Cloud.png" alt="Photo 1" class="dev__photo dev-p1" />
                            <img src="images/Project.jpg" alt="Photo 2" class="dev__photo dev-p2" />
                            <img src="../course_images/MS_access.png" alt="Photo 3" class="dev__photo dev-p3" /> */}
                            <img src="http://www.alphaonemedia.ca/teaching/DataVerse.png" alt="Photo 1" class="dev-photo dev-p1" />
                            <img src="http://www.alphaonemedia.ca/teaching/MS_Access.png" alt="Photo 1" class="dev-photo dev-p2" />
                            <img src="http://www.alphaonemedia.ca/teaching/SQL_Cloud.png" alt="Photo 1" class="dev-photo dev-p3" />
                        </div>
          </div>
        </div>
      </section>

      
      {/* <section className="databasedev">
        <h1>DATABASE DEVELOPMENT</h1>

        <div className="hero-content">
          <table classname="table">
            <tr>
              <td className="column2sharepoint">
                A lot of companies are not large enough to purchase software
                such as SAP or pay thousands of dollars in licensing fees per
                year to purchase some database designed by someone in the
                industry. However, all the data your collecting, usually in
                Excel, no longer works properly and you cannot get the
                information you need to make proper decisions. Your solution is
                to create a database in-house using Microsoft Access in
                conjunction with PowerApps (if you want access to your data
                online) and I can help you with that.
                <br />
                <br />I have designed Access databases for over 20 years and
                have written manuals for a local Post Secondary school for this
                product. I can design, implement and deploy your database,
                taking your data from Excel into a much better way of reporting,
                with relevant features such as emailing reports and creating
                dashboards for your employees. Furthermore, integrate this
                database into PowerBI for powerful visual reports for your
                management team both for hard copies and online viewing on
                mobile devices.
                <br />
                <br />
                Furthermore, I can then train your employees to maintain and
                support your database to add new features as your organization
                grows or I can help maintain your systems as well. Having
                accurate data at your fingertips is kingpin to any organization
                and it all starts with creating a central data location while
                using online apps to have your data accessible to anyone in the
                organization from anywhere, and I can you get there!
                <br />
                <br />
              </td>
            </tr>
            <tr>
              <td
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  /* paddingLeft: "150px",
                }}>
                <img src="http://www.alphaonemedia.ca/teaching/DatabaseDev.png"></img>
              </td>
            </tr>
          </table>
        </div>
      </section> */}
      <section className="hero6">
        <div className="hero-content2">
          <table>
            <tr>
              <td className="btm-column1">
                <b>ALPHA ONE MEDIA</b>
              </td>
              <td className="btm-column2">
                <b>LINKEDIN</b>
              </td>
              <td className="btm-column2">
                <b>QUICK MENU</b>
              </td>
              <td className="btm-column2">
                <b>JOIN MY COMMUNITY</b>
              </td>
            </tr>
            <tr>
              <td className="btm-column1">
                Alpha One Media is an IT consulting company that specializes in
                Microsoft training both in classroom and Virtual. As well as
                development of video courses, SharePoint sites and PowerApps.
              </td>
              <td className="btm-column2">
                <a
                  href="https://www.linkedin.com/in/robinsolonenko/"
                  target="_blank"
                >
                  My LinkedIn Profile
                </a>
              </td>
              <td className="btm-column3">
                Home
                <br />
                About Me
                <br />
                Course
                <br />
                Services
                <br />
                Contact Me
              </td>
              <td>
                <MailchimpForm />
              </td>
            </tr>
          </table>
        </div>
      </section>
      <Footer />
    </section>
  );
};

export default Courses;
