import React from "react";
import "./Home.css";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import "../components/hero/mailchimpForm.css";
import "./form.css";

import MailchimpForm from "../components/hero/mailchimp";

const Courses = () => {
  return (
    <section>
      <Header />
      <section className="contactform">
        
        <div className="rowform">
          <div className="contactboxframe">
            <div className="column">
            <div className="actualform">
              
              <form action="mailto:robins@alphaonemedia.ca" method="post" className="realform">
                  <div>
                    <h2>Feel free to send me a message...</h2>
                  </div>
                <div className="form-group">
                    <input type="Text" className="form-input" placeholder="Full Name" id="name" required></input>
                    <label for="name" className="form-label">Full Name</label>
                </div>
                <div className="form-group">
                    <input type="text" className="form-input" placeholder="Telephone" id="telephone" required></input>
                    <label for="telephone" className="form-label">Telephone</label>
                </div>
                <div className="form-group">
                    <input type="email" className="form-input" placeholder="Your Email Address" id="email" required></input>
                    <label for="email" className="form-label">Your Email Address</label>
                </div>
                <div className="form-group">
                    <textarea className="form-input" rows={5} placeholder="Comments" id="comments" required></textarea>
                    <label for="comments" className="form-label">Comments</label>
                </div>
                <div className="form-group-button">
                    <button className="form-button" type="submit">Send Message to Robin</button>
                </div>
              </form>
              </div>
              
            </div>
            <div className="column">
                <p className="paragraphtop">Although I can teach the entire MS Office suite, my clients needs have 
                been for Power Platform products such as Power BI, Power Apps and Power Automate along with SharePoint online
                for both classic and modern experiences.</p>
                <p className="paragraph">I can offer all these coures as Instructor Led training or
                Virtual training and both come with the required manuals in PDF
                format. Please take a look at the courses on the courses page.</p>
                <p className="paragraph">As added value, all my clients will get videos of each course
                content I instruct broken down by chapter and topic videos. The students can refer back
                to these videos for future reference.</p>
              </div>
          </div>
         </div>
      </section>

     
      <section className="hero6">
        <div className="hero-content2">
          <table>
            <tr>
              <td className="btm-column1">
                <b>ALPHA ONE MEDIA</b>
              </td>
              <td className="btm-column2">
                <b>LINKEDIN</b>
              </td>
              <td className="btm-column2">
                <b>QUICK MENU</b>
              </td>
              <td className="btm-column2">
                <b>JOIN MY COMMUNITY</b>
              </td>
            </tr>
            <tr>
              <td className="btm-column1">
                Alpha One Media is an IT consulting company that specializes in
                Microsoft training both in classroom and Virtual. As well as
                development of video courses, SharePoint sites and PowerApps.
              </td>
              <td className="btm-column2">
                <a
                  href="https://www.linkedin.com/in/robinsolonenko/"
                  target="_blank"
                >
                  My LinkedIn Profile
                </a>
              </td>
              <td className="btm-column3">
                Home
                <br />
                About Me
                <br />
                Course
                <br />
                Services
                <br />
                Contact Me
              </td>
              <td>
                <MailchimpForm />
              </td>
            </tr>
          </table>
        </div>
      </section>
      <Footer />
    </section>
  );
};

export default Courses;
