import React from 'react';
import './footer.css';

function Header() {
  return (
    <header className="footer">
      <div className="footer__logo">
        <img src={'http://www.alphaonemedia.ca/teaching/ALPHA1MC_LogoSMWHITE.png'} alt="Alpha One Media logo" />
      
      </div>
      <div className='footer-copyright'>Copyright 1997-2023 ALPHA ONE MEDIA.  ALL RIGHTS RESERVED.</div>
    </header>
  );
}

export default Header;
