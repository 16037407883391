import { createBrowserRouter, Router, RouterProvider } from "react-router-dom";


import './App.css';
import Home from './pages/Home';
import AboutMe from './pages/AboutMe';
import Courses from './pages/Courses';
import InstructorLed from './pages/InstructorLed';
import SharePointDev from './pages/SharePointDev';
import DatabaseDev from './pages/DatabaseDev';
import ContactMe from './pages/ContactMe';
import RootLayout from './pages/Root';

const router = createBrowserRouter([
  {path: '/', element: <RootLayout />, children: [
    {path: '/', element: <Home />},
    {path: '/AboutMe', element: <AboutMe />},
    {path: '/Courses', element: <Courses />},
    {path: '/InstructorLed', element: <InstructorLed />},
    {path: '/SharePointDev', element: <SharePointDev />},
    {path: '/DatabaseDev', element: <DatabaseDev />},
    {path: '/ContactMe', element: <ContactMe />},
  ],
},  
]);

function App() {
  return(
  <RouterProvider router={router} />
   )
}


export default App;
