
import React from 'react';
import './mailchimpForm.css';

const MailchimpForm = () => {
  return (
    <div id="mc_embed_signup">
      <form
        action="https://alphaonemedia.us4.list-manage.com/subscribe/post?u=31c16b8f304379632f5de3bdc&amp;id=c260036f17&amp;f_id=001bb3e8f0"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="validate"
        target="_blank"
        noValidate
      >
        <div id="mc_embed_signup_scroll">
          {/* <div className="indicates-required">
            <span className="asterisk">*</span> indicates required
          </div> */}
          <InputField
            label="Email Address"
            name="EMAIL"
            type="email"
            required
            /* helperText="We will never share your email with anyone else." */
          />
          <div id="mce-responses" className="clear foot">
            <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
            <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
          </div>
          <div className="optionalParent">
            <div className="clear foot">
              <SubmitButton value="Subscribe" />
              {/* <Logo /> */}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

const InputField = ({ label, name, type, required, helperText }) => {
  return (
    <div className="mc-field-group">
      <label htmlFor={`mce-${name}`}>
        {label} <span className="asterisk">{required && '*'}</span>
      </label>
      <input type={type} name={name} className={`required ${type}`} id={`mce-${name}`} required={required} />
      <span id={`mce-${name}-HELPERTEXT`} className="helper_text">
        {helperText}
      </span>
    </div>
  );
};

const SubmitButton = ({ value }) => {
  return <input className="mailchimp-button" type="submit" value={value} name="subscribe" id="mc-embedded-subscribe" /* className="button" */ />;
};

const Logo = () => {
  return (
    <p className="brandingLogo">
      <a href="http://eepurl.com/ikYUCb" title="Mailchimp - email marketing made easy and fun">
        <img src="https://eep.io/mc-cdn-images/template_images/branding_logo_text_dark_dtp.svg" alt="Mailchimp logo" />
      </a>
    </p>
  );
};

export default MailchimpForm;
