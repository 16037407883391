
import React from "react";
import './Home.css';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import '../components/hero/mailchimpForm.css';

import  MailchimpForm from '../components/hero/mailchimp';

const Courses = () => {
  return (
    <section>
   
    <section className="coursestop">
      
    </section>
    <section className="sharepointdev">
     <h1>SHAREPOINT CONSULTING</h1>
     <div className="hero-content">
      <table classname='table'>
         <tr>
         <td className='column2sharepoint'>In my experience, SharePoint is one of the most underutilized application in all
          of Microsoft’s software.  It is a great repository for all your company documents as well as a collaborative
           tool for all your employees.  SharePoint can replace all your “shared” drives and allow your employees to
            work virtually anyplace in the world and have access to all their documents.<br/><br/>Your employees fall
             into two groups – Users & Owners – and I can help you determine who is in which group and get them trained
              on their responsibilities as well, as design and implement your own SharePoint site for your entire
               organization to benefit from.<br/><br/>SharePoint can be intimidating but really it’s just Microsoft
                FrontPage on steroids.  It’s a glorified web page design effort that I can help your employees to get
                 comfortable with in no time.  So, from SharePoint 2010 to today’s SharePoint Online  version I can help
                  get your organization online and ready for tomorrow!<br/><br/></td>
          </tr>
          <tr>
          <td style={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img src='http://www.alphaonemedia.ca/teaching/SharePointClass.png'></img></td>
          </tr>
       </table>
       </div>
   </section>
   <section className="hero6">
      <div className="hero-content2">
       <table>
        <tr>
          <td className='btm-column1'><b>ALPHA ONE MEDIA</b></td>
          <td className='btm-column2'><b>LINKEDIN</b></td>
          <td className='btm-column2'><b>QUICK MENU</b></td>
          <td className='btm-column2'><b>JOIN MY COMMUNITY</b></td>
        </tr>
        <tr>
          <td className='btm-column1'>Alpha One Media is an IT consulting company that specializes in Microsoft training both in classroom and Virtual. 
             As well as development of video courses, SharePoint sites and PowerApps.</td>
          <td className='btm-column2'><a href='https://www.linkedin.com/in/robinsolonenko/' target='_blank' >My LinkedIn Profile</a></td>
          <td className='btm-column3'>Home<br/>
          About Me<br/>
          Course<br/>
          Services<br/>
          Contact Me</td>
          <td>
          <MailchimpForm />
          </td>
        </tr>
       </table>
      </div>
      </section>
      <Footer />
   </section>
  );
};

export default Courses;
