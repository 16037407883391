import { Outlet } from 'react-router-dom';
import Menu from '../components/sideMenu/sidemenu';


function RootLayout (){
    return (
    <>
    <Menu />
    <main>
        <Outlet />
    </main>
    
    </>
    );
}

export default RootLayout;