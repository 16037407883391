import React from 'react';
import './header.css';

function Header() {
  return (
    <header className="header">
      <div className="header__logo">
        <img src={'http://www.alphaonemedia.ca/teaching/alpha1mc_logo_text_white.png'} alt="Alpha One Media logo" />
      </div>
      
      <nav className="header__nav">
        <ul className="header__nav-list">
        {/*   <li className="header__nav-item">
            <a href="http://alphaonemedia.ca" className="header__nav-link">Home</a>
          </li> */}
          {/* <li className="header__nav-item">
            <a href="#" className="header__nav-link">About</a>
          </li>
          <li className="header__nav-item">
            <a href="#" className="header__nav-link">Courses</a>
          </li>
          <li className="header__nav-item">
            <a href="#" className="header__nav-link">Services</a>
          </li> */}
          <li className="header__nav-item">
            <div style={{color: 'white', fontWeight: 'bold'}}>Menu</div>
            {/* <h5 className="header__nav-link">Menu</h5> */}
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
