import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import "./sideMenu.css";

function Menu() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (event.clientX > window.innerWidth - 180) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <nav
      className={`menu ${isOpen ? "open" : ""}`}
      onMouseEnter={toggleMenu}
      onMouseLeave={toggleMenu}>
      <ul>
        <li>
          <Link to='/'>Home</Link>
        </li>
        <li>
          <Link to='/AboutMe'>About Me</Link>
        </li>
        <li>
          <Link to='/Courses'>Courses</Link>
        </li>
        <li >
          <Link to='/InstructorLed'>Microsoft</Link>
        </li>
        <li >
          <Link to='/SharePointDev'>SharePoint Dev</Link>
        </li>
        <li >
          <Link to='/DatabaseDev'>Database Dev</Link>
        </li>
        <li>
          <Link to='/ContactMe'>Contact</Link>
        </li>
      </ul>
       
    </nav>
  );
}
export default Menu;