
import React from "react";
import './Home.css';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import '../components/hero/mailchimpForm.css';

import  MailchimpForm from '../components/hero/mailchimp';

const AboutMe = () => {
  return (
    <section>
    <Header />
    
     <section className="aboutmesection">
     <h1>About Your Instructor</h1>
     
     <div className="hero-content">
      
      <table className='table'>
         <tr>
          <td className='column1'><img src='http://www.alphaonemedia.ca/teaching/RobinAboutMe.png'></img></td>
        </tr>
        <tr>
            <td className='column2'><br/><br/>HI! My name is Robin Solonenko and I am the CEO of Alpha One Media.
             I have been a Microsoft Instructor since 1997 and a big nerd since I was 12 years old.        
             I have been around computers back when we used Commodore 64’s and when 3 1/2″ floppies were new and innovative.
             Things have changed and grown since then and so has my extensive background in Microsoft end user products. 
             I started classroom training in 1997 and by 1999 received my MCP and MSCE. <br/><br/>I can instruct anything from
             the MS Office suite to Microsoft’s Power Platform suite of products – Power BI, Power Apps and Power Automate.
             If you’re the Project Management type I can get you going on MS Project.  Understanding front end software
             comes very natural and easy for me and teaching this knowledge to users in a simple and easy way to understand 
             is something I always have done. <br/><br/>After the recent pandemic event, companies have been forced to look at more 
             closely the many processes in their company including development and training and the cost of doing such. 
             By removing the middleman and dealing directly with the Instructor or Developer your company can save time and 
             money without compromising on quality and be more efficient. Let’s face it, those training companies you contract 
             to get the training done, end up hiring people like me at the end of the day. <br/><br/>I encourage you to look at the
             training courses and services I provide and how I can help you and your organization move forward either in 
             person or with virtual training to help your remote locations feel connected. If you like to learn at your own 
             pace, I have all my courses one my own platform <b><a href='http://www.whatdoyouwanttolearntoday.ca' target='_blank' >www.WhatDoYouWantToLearnToday.ca</a> </b>as well. You will always have
             access to current video files and manuals.

                Robin Solonenko
                CEO Alpha One Media</td>
        </tr>
       </table>
       
     </div>
   </section>

  
    
    <section className="hero6">
      <div className="hero-content2">
       <table>
        <tr>
          <td className='btm-column1'><b>ALPHA ONE MEDIA</b></td>
          <td className='btm-column2'><b>LINKEDIN</b></td>
          <td className='btm-column2'><b>QUICK MENU</b></td>
          <td className='btm-column2'><b>JOIN MY COMMUNITY</b></td>
        </tr>
        <tr>
          <td className='btm-column1'>Alpha One Media is an IT consulting company that specializes in Microsoft training both in classroom and Virtual. 
             As well as development of video courses, SharePoint sites and PowerApps.</td>
          <td className='btm-column2'><a href='https://www.linkedin.com/in/robinsolonenko/' target='_blank' >My LinkedIn Profile</a></td>
          <td className='btm-column3'>Home<br/>
          About Me<br/>
          Course<br/>
          Services<br/>
          Contact Me</td>
          <td>
          <MailchimpForm />
          </td>
        </tr>
       </table>
      </div>
      </section>
      <Footer />
   </section>
  );
};

export default AboutMe;
