
import React from "react";
import './Home.css';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import '../components/hero/mailchimpForm.css';

import  MailchimpForm from '../components/hero/mailchimp';

const Home = () => {
  return (
    <section>
    <Header />
    <section className="hero">
      <div className="hero-content logoAnimated">
       {/*  <h1 className="hero-title">Alpha One Media</h1>
        <p className="hero-subtitle">Innovative Marketing Solutions</p>
        <button className="hero-cta">Learn More</button> */}
      </div>
    </section>
     <section className="hero2">
     <p>Hi, my name is Robin...</p>
     <div className="hero-content">
      
      <table classname='table'>
         <tr>
          <td classname='column2'>...and I can help you navigate Microsoft’s suite of 
          products to teach your employees the skillsets they require. From 
          Instructor Led training to Virtual training to Instructional videos, 
          I can help build for your confidence in the products you use for you and your employees.<br /><br />
          I have been instructing Microsoft products since 1997 and have developed
          course content since 2013 by writing manuals for all the courses I instruct today. 
          From the Power line of products (Power BI, PowerApps, Power Automate) to SharePoint 
          Online to Access Databases I can teach you and your employees in any environment.<br/><br />
          From classroom instruction to virtual training, I can offer your employees 
          hands on training and skills development for all levels of these products and 
          because I write my own content (manuals) for these products I can maintain their 
          accuracy and keep them current with all the changes that Microsoft pushes out.<br /><br/>
          If learning at your own pace is preferred, then you can access all my course videos 
          that I teach on my subscription based platform - <b><a href='http://www.whatdoyouwanttolearntoday.ca' target='_blank' >www.WhatDoYouWantToLearnToday.ca</a></b>. 
          Here you can still get great instructional content, the custom designed manual and learn at your own time and pace.</td>
          <td ><img src='http://www.alphaonemedia.ca/teaching/Robin_FrontPage.png'></img></td>
        </tr>
       </table>
       
     </div>
   </section>

   <section className="hero3">
      <div className="hero-content2">
       <h1 className="hero-title">VIRTUAL TRAINING</h1>
        <p className="hero-subtitle">The recent pandemic has forced companies to look at new ways of
         connecting their employees.  Virtual Training is now a must have for all organizations especially
          if you have remote offices.  All your employees can enjoy the same level of training no matter where they are located.</p>
        <br/>
        <table className='table'>
          <tr>
            <td className="virtualTrainingPoints ">MORE VALUE</td>
            <td className="virtualTrainingPoints ">LEARN FROM HOME</td>
            <td className="virtualTrainingPoints ">HD VIDEO FEED</td>
            <td className="virtualTrainingPoints ">SAVE TRAVEL COSTS</td>
            </tr>
        </table>
       
      </div>
    </section>

    <section className="hero4">
      <div className="hero-content2">
      <table classname='table'>
        <tr><td className='benefit-title' colspan='2' >ALPHA ONE MEDIA BENEFITS</td></tr>
        <tr><td className='benefit-title2' colspan='2'>CUT OUT THE MIDDLE MAN AND HIRE DIRECT!</td></tr>
         <tr>
          <td>
            <table>
              <tr><td className='benefit-column-title'>INSTRUCTOR LED OR VIRTUAL TRAINING</td></tr>
              <tr><td className='benefit-column-text'>I can come into your organization or connect virtually at a fraction of any competitors price!<br/><br/><br/></td></tr>
              <tr><td className='benefit-column-title'>INSTRUCTIONAL VIDEO COURSES</td></tr>
              <tr><td className='benefit-column-text'>If learning at your own pace is preferred, 
              then you can access all my course videos that I teach on my subscription based platform - <b><a href='http://www.whatdoyouwanttolearntoday.ca' target='_blank' >www.WhatDoYouWantToLearnToday.ca</a></b>.<br/><br/><br/></td></tr>
              <tr><td className='benefit-column-title'>SHAREPOINT TRAINING & DEVELOPMENT</td></tr>
              <tr><td className='benefit-column-text'>I can get your site created and your users trained on how to maintain it.<br/><br/><br/></td></tr>
            </table>
          </td>
          <td classname='column1'><img src='http://www.alphaonemedia.ca/teaching/Middle_Man.png'></img></td>
        </tr>
       </table>
      </div>
    </section>
   
    
    <section className="hero6">
      <div className="hero-content2">
       <table>
        <tr>
          <td className='btm-column1'><b>ALPHA ONE MEDIA</b></td>
          <td className='btm-column2'><b>LINKEDIN</b></td>
          <td className='btm-column2'><b>QUICK MENU</b></td>
          <td className='btm-column2'><b>JOIN MY COMMUNITY</b></td>
        </tr>
        <tr>
          <td className='btm-column1'>Alpha One Media is an IT consulting company that specializes in Microsoft training both in classroom and Virtual. 
             As well as development of video courses, SharePoint sites and PowerApps.</td>
          <td className='btm-column2'><a href='https://www.linkedin.com/in/robinsolonenko/' target='_blank' >My LinkedIn Profile</a></td>
          <td className='btm-column3'>Home<br/>
          About Me<br/>
          Course<br/>
          Services<br/>
          Contact Me</td>
          <td>
          <MailchimpForm />
          </td>
        </tr>
       </table>
      </div>
      </section>
      <Footer />
   </section>
  );
};

export default Home;
